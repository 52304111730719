import {config} from 'config';

export const environment = {
    production: true,
    ver: '24025',
    version: 'Ver: 08/04/2025 14:25:47',
    version_ts: '1744115147316',
    firebase: {
        apiKey: 'AIzaSyCiv74FkdNizesgiVNHSrPZUkN90LKZUD8',
        authDomain: 'optimise-enerplan.firebaseapp.com',
        databaseURL: 'https://optimise-enerplan.firebaseio.com',
        projectId: 'optimise-enerplan',
        storageBucket: 'optimise-enerplan.appspot.com',
        messagingSenderId: '559963427417',
        appId: '1:559963427417:web:b12c6f260ab82c2a369748'
    },
    apiURL: config.apiUrl
};
